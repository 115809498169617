import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { useQuery } from '@apollo/client'
import { user } from '@graphql'
import { CacheUser, ResponseType } from '@types'
import { AccountPage } from '@pages/components'
import { AccountLayout } from '@layouts'
import { useWindowSize } from '@hooks'
import './profile.scss'

interface AccountProfileProps {}

const AccountProfile: React.FC<AccountProfileProps> = () => {
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const [isShippingInfoFormOpened, toggleShippingInfoForm] = useState(false)
  const { data, loading } = useQuery<ResponseType<CacheUser>>(user.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })
  const currentUser = data && data.res

  if (!loading && !currentUser) navigate('/home/')
  if (!currentUser) return null

  if (isMobileScreen) return <AccountPage.MobileProfile user={currentUser} />

  return (
    <AccountLayout pageClassName={'account-profile'}>
      <h6 className={'account-layout-page-title'}>PROFILE</h6>
      <AccountPage.BasicInfo />
      <AccountPage.ShippingInfo
        isShippingInfoFormOpened={isShippingInfoFormOpened}
        toggleShippingInfoForm={toggleShippingInfoForm}
        direction={'horizontal'}
      />
      <AccountPage.PaymentInfo />
    </AccountLayout>
  )
}

export default AccountProfile
